@import '../../scss/variables';

.container {
    margin: 48px auto;
    overflow: hidden;

    .imageTileGrid{
        width: 100%;
    }

    ul[class*="slick-dots"] {
        margin-top: 16px;
        padding: 0 24px;
        text-align: center;
    
        li {
          display: inline-block;
          margin: 16px 8px 0;
    
          &[class*="slick-active"] {
            pointer-events: none;
    
            button {
              background-color: $grey;
            }
          }
    
          &,
          button {
            height: 14px;
            width: 14px;
    
            &::before {
              content: none;
            }
          }
    
          button {
            background-color: $lightgrey;
            border-radius: 50%;
            color: transparent;
            font-size: 0;
            line-height: 0;
            border: none;
            &[disabled] {
              background-color: $grey;
            }
          }
        }
      }
}

.itemCard{
    max-width: 90%;
    width: 100%;
}
.imageGrid {
    position: relative;
    padding-right: 4px;
    width: 100% !important;

    .itemImage {
        height: 100%;
        width: 100%;
    }

    &:hover, &:focus, &:focus-within {
        .gradientHover {
            opacity: 0.8;
        }
    }
}

.sliderContainer{
    width: 100% !important;
}

.buttonGroup{ 
    display: none;
}
.buttonDot{
    border: 1px solid;
    border-radius: 1px;
    height: 10px;
    width: 10px;
    border-radius: 14px;
    margin-right: 14px;
}

.gradientHover {
    align-items: center;
    background: $red;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    opacity: 0;
    padding: 31px;
    position: absolute;
    text-align: center;
    transition: opacity .3s ease-in-out;
    top: 0;
    width: calc(100% - 4px);

    .itemDescription {
        color: $white;
        font-size: 18px;
        font-family: $sans-serif-normal;
        font-weight: $sans-normal;
        width: 100%;

        &:not(:only-child) {
            margin-bottom: 20px;
        }
    }

    .link {
        color: $white;
        font-family: $sans-serif-medium;
        font-weight: $sans-medium;
        line-height: 20px;
        margin: 0;

        &:after {
            color: $white;
        }
    }
}

@media screen and (max-width: $mobile-larger) {
    .gradientHover {
        bottom: 0;
        height: auto;
        opacity: 1;
        padding: 8px;
        top: inherit;

        .itemDescription,
        .link {
            font-size: 14px;
        }

        .itemDescription {
            line-height: 24px;

            &:not(:only-child) {
                margin-bottom: 12px;
            }
        }
    }

    .imageGrid {
        &:hover {
            .gradientHover {
                opacity: 1;
            }
        }
    }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
    .itemCard{
        margin-right: 10px;
        margin-left: 10px;
    }

    .sliderContainer{
        width: max-content !important;
        transition: all 500ms;
    }

    .buttonGroup{
        display: flex !important;
        justify-content: center !important;
        margin-top: 25px;
    }

    .buttonDot{
        border: 1px solid;
        border-radius: 1px;
        height: 10px;
        width: 10px;
        border-radius: 14px;
        margin-right: 14px;
    }

    .carousel__slider {
        padding-left: 12.5%;
        padding-right: 12.5%;
    }

    .carousel__inner-slide {
        width: calc(50% - 10px);
        height: calc(50% - 10px);
        left: 10px;
        top: 10px;
    }
}

@media screen and (min-width: 700px) and (max-width: 900px) {
    .itemCard{
        margin-right: 15px;
        margin-left: 15px;
    }
}

@media screen and (max-width: 600px) {
    .itemCard{
        width: 100%;
    }
    .imageGrid{
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        top: 10px;
    }

    .sliderContainer{
        width: 500% !important;
        transition: all 500ms;
        transform: rotateY(-60 deg);
    }

    .buttonGroup{
        display: flex !important;
        justify-content: center !important;
        margin-top: 25px;
    }

    .buttonDot{
        border: 1px solid;
        border-radius: 1px;
        height: 10px;
        width: 10px;
        border-radius: 14px;
        margin-right: 14px;
    }

    .carousel__slider {
        padding-left: 25% !important;
        padding-right: 25%;
    }

    .carousel__inner-slide {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        left: 10px;
        top: 10px;   
    }
}

@media screen and (min-width: 500px) and (max-width: 600px) {
    .itemCard{
        width: 85% !important;
    }
}

@media screen and (min-width: 600px) and (max-width: 700px) {
    .itemCard{
        width: 280px !important;
        margin-left: 10px;
    }
}

@media screen and (min-width: 700px) and (max-width: 800px) {
    .itemCard{
        width: 320px !important;
        margin-left: 10px;
    }
}

@media screen and (min-width: 800px) and (max-width: 850px) {
    .itemCard{
        width: 360px !important;
        margin-left: 0px !important ;
    }
}

@media screen and (min-width: 850px) and (max-width: 900px) {
    .itemCard{
        width: 360px !important;
        margin-left: 10px !important ;
    }
}